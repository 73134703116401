import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
    </PageDescription>
    <h2>{`Kockázatelemzés`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.138888888888886%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVQoz3WR3W4UMQyF5/3fAiSegkvEDQghhOCiFZSLdn9YzXZn8uckjj+UdIS2KkQ6in2i2D4+0++LY14DQQpLELxkcqm4lAfWmPH93uIlyuClFCS/xHRYIt/2ji87z27NBKmAcQplcDenSK6N42Pk+zHwsGRSUUwb/zrTGhKvPtzz9mYehfqk0Hh/d+H1xx3ey+Df/Tjz5tMeqUrKFc2FZkZr7Rmm+3nFtNBUmb3gorCscTxiSu0rqBVrOhqVWimlkJMMvq/nGtPuMRB7R4ODy/w6J5ZUKVmxZnAlzewq+J/k/cUTJWNmo7AUHbK6JFOllUprhm6SejHrced74Z5bG/+H5LML+Jg2KZWzF5busIuEKM+kpVw4ucQa5Ol9y7vrS8xE6S5fIlLq35FX0eHm7SkSpb5wc79mPj84fs4Rkcrxkvh68NzNiaLG5LYJtuVQmxGyEopS9EnKNaQ2fNaBqt2khstKLIo24w/rkrxSGZ8zFQAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "folyamat",
        "title": "folyamat",
        "src": "/static/61cc39016b7ac950f1ccde3d2dddf333/3cbba/external-connections.png",
        "srcSet": ["/static/61cc39016b7ac950f1ccde3d2dddf333/7fc1e/external-connections.png 288w", "/static/61cc39016b7ac950f1ccde3d2dddf333/a5df1/external-connections.png 576w", "/static/61cc39016b7ac950f1ccde3d2dddf333/3cbba/external-connections.png 1152w", "/static/61cc39016b7ac950f1ccde3d2dddf333/58488/external-connections.png 1604w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <ol>
      <li parentName="ol">{`A felhasználó megnyitja az integráló fél azon aloldalát, ahol a Péntech Kockázatelemző Modul integrálva van.`}</li>
      <li parentName="ol">{`Az integráló weboldal megjeleníti az iframe-et.`}</li>
      <li parentName="ol">{`Betölt a Modul.`}</li>
      <li parentName="ol">{`A felhasználó a beágyazott oldalon beír egy cégnevet.`}</li>
      <li parentName="ol">{`Majd elindítja az elemzést.`}</li>
      <li parentName="ol">{`A beágyazott oldal megjeleníti az elemzés eredményét`}</li>
      <li parentName="ol">{`A Péntech rendszere elmenti a keresett cég(ek) adószámát és a lekérdezés eredményét, későbbi riportálás céljából.`}</li>
    </ol>
    <p>{`Kódolatlan payload nem bejelentkezett felhasználó esetén:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{"session": "azonosito123456"}
`}</code></pre>
    <p>{`Kódolatlan payload bejelentkezett felhasználó esetén:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{"session": "azonosito123456", "user": "user_id123456"}
`}</code></pre>
    <p>{`Példa URL:`}</p>
    <p><inlineCode parentName="p">{`https://quickrisk.module.pentech.hu?partner-key=nlo4Xf1dn-nak&payload=rpeo%2FGeYN5K6a0Axv%2FW0fJDdQOWYP5SUDAk%2FtquuHFYxUhtxAZ%2FXyeVvZ%2BKV5NH3%3A012be27fbbb413d2`}</inlineCode></p>
    <p>{`Amennyiben a felhasználó eléri a számára kijelölt napi keretet, úgy a beágyazott oldalon nem fog tudni több céget hozzáadni a kockázatelemzéshez és egy hibaüzenetet kap a számára aktuális instrukciókkal.`}</p>
    <h2>{`Riportálás`}</h2>
    <p>{`A Péntech a szerződésben foglalt időközönként és módon vállalja a mentett lekérdezési adatok megfelelő részletességgel való összeállítását és továbbítását a szerződésben foglalt formátumban.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      